@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Helvetica";
    src: local("Helvetica"), url("./assets/fonts/Helvetica.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Bold";
    src: local("Helvetica-Bold"), url("./assets/fonts/Helvetica-Bold.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
}

body {
    background-color: #F4F4F4;
    font-family: "Helvetica", Arial, sans-serif;
}

.font-bold {
    font-family: "Helvetica Bold", Arial, sans-serif;
}

#header_sub img.banner {
    width: 100%;
    height: 220px;
}

.parser-html {
    clear: both;
}

.parser-html h3,
.parser-html-teaser h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.parser-html p,
.parser-html-teaser p {
    margin-bottom: 1rem;
}

.parser-html strong,
.parser-html-teaser strong {
    font-family: Helvetica Bold,Arial,sans-serif;
    font-weight: 700;
}

.video-responsive iframe,
.video-responsive .image {
    float: left;
    margin: 0 30px 30px 0;
}

.video-responsive .image {
    width: 249px;
    height: 250px;
}

.player_play {
    position: absolute;
    top: 80px;
    left: 80px;
    width: 80px;
    height: 80px;
}

/* Loader */
/* Colors */
:root {
    --white: #ffffff;
    --turquoise: #3DBABA;
    --powder-blue:#87AEAF;
    --alice-blue:#D7EFF7;
    --anti-flash-white:#F6F9FD;
    --oxford-blue:#011529;
    --aquamarine: #7FFFD4;
    --payne-gray:#607080;
    --prussian-blue:#082E47;
    --rich-black:#03101C;
    --brand: var(--turquoise);
    --fg:var(--oxford-blue);
    --fg-1:var(--rich-black);
    --fg-2:var(--prussian-blue);
    --dark: var(--oxford-blue);
    --light: var(--white);
    --bg: var(--white);
    --bg-1:var(--alice-blue);
    --bg-2:var(--anti-flash-white);
    --neutral: var(--powder-blue);
    --tone-1: var(--turquoise);
    --tone-2: var(--aquamarine);
}

.fbottom, .ll {
    fill: none;
}

.fbottom {
    stroke-width: inherit;
    stroke: var(--neutral);
    stroke-opacity: 0.3;
}

.ll {
    stroke: var(--brand);
    animation: loop 3s linear infinite;
    stroke-width: 0.35;
    stroke-dasharray: 3 24.5;
    stroke-linecap: round;
}

.finny {
    stroke-width: 1.43;
    animation: rot 36s linear infinite;
}

.shad {
    animation: raise 3s linear infinite;
}

.shad.odd {
    animation-delay: -1.5s;
}

@keyframes raise {
    0%, 20% {
        opacity: 1;
    }
    30%, 70% {
        opacity: 0;
    }
    80%, 100% {
        opacity: 1;
    }
}
.ll {
    animation-delay: -1.5s;
}

.finny {
    perspective: 500px;
}

@keyframes loop {
    0% {
        stroke-dashoffset: 0.5;
    }
    100% {
        stroke-dashoffset: 28;
    }
}
.clip-shadow {
    stroke-linecap: butt;
    fill: none;
    opacity: 0.3;
    stroke-width: inherit;
    stroke: url(#sgrad);
}

#sgrad stop {
    stop-color: var(--bg-3);
}

@media (prefers-color-scheme: light) {
    #sgrad stop {
        stop-color: #2345;
    }
}
